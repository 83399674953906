body, html{
  font-family: 'Open Sans', sans-serif;
}

// Code formatting
pre, code {
  border: 1px solid #e1e1e8;
  border-radius: 3px;
  background-color: #f7f7f9;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

#main-container {
  width: 900px;
  margin-left: auto;
  margin-right: auto;
}

#header {

  #name {
    font-size: 3em;
    color: #000;
    text-decoration: none;
  }

  #menu {
    background-color:  #2E2E2E;
    color: #fff;
    margin: 0px;
    padding: 10px;
    font-weight: 400;
    text-transform: uppercase;
    a {
      padding: 10px;
      text-decoration: none;
      color: inherit;
    }
    a:hover{
      background-color: #000;
      color: #39c;
    }
  }
}

.content{
  font-size: 12px;
  a{
    color: #39c;
  }
  a:hover{
    color: #036;
  }
}

#footer{
  border-top: 1px #aaa solid;
  margin-top: 30px;
  padding-top: 5px;
  text-align: center;
  font-size: 12px;
  color: #aaa;
  a{ color: #aaa; }
  a:hover{ color: #444; }
}

section{
  .left{
    float:left;
    width: calc(50% - 10px);
    margin-right: 10px;
  }
  .right{
    float: right;
    width: calc(50% - 10px);
    margin-left: 10px;
  }
  .clear{
    clear: both;
  }
}

h1, h2, h3, h4, h5, h6{
  font-weight: 300;
  margin-bottom: 10px;
}

h1{
  text-transform: uppercase;
  font-size: 32px;
}

h2{
  border-bottom: 1px black solid;
  font-size: 24px;
}

.center{
  padding: 15px;
  text-align: center;
}

/* Server Connection Details */
#server-details{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border: 1px solid #000;
  border-radius: 10px;
}

.server-name{
  font-size: 18px;
  text-shadow: 1px 1px 1px #999;
}

.server-address{
  font-size: 14px;
  font-family: monospace;
}

